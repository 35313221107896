import 'regenerator-runtime/runtime'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app/App'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import './index.css'
import { getSentryConfig, getHeapConfig } from 'do-cornucopia-root'
import { heapAnalytics as createToastAnalytics } from '@toasttab/do-secundo-heap-analytics'

const tempWindow: any = window

const heapAnalytics = createToastAnalytics()
tempWindow.toastAnalytics = heapAnalytics

const heapId = getHeapConfig(tempWindow.OO_GLOBALS.mode)
heapAnalytics.load({
  /**
   * Since we are loading the heap ID via do-cornucopia-root,
   * we do not need env-specific heap identifiers.
   */
  heapIDs: {
    DEV: heapId,
    PREPROD: heapId,
    PROD: heapId
  },
  configOptions: {},
  loadedCb() {
    console.log('Heap loaded')
  }
})

const sentry = getSentryConfig(tempWindow.OO_GLOBALS.mode)

const mountPoint = 'single-spa-application:root'
const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'corn-layout-spa',
  rootComponent: App,
  sentry,
  domElementGetter: () => {
    const el = document.getElementById(mountPoint)
    if (!el) {
      throw new Error(
        `${mountPoint} does not exist, it is required for layout spas`
      )
    }
    return el
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
